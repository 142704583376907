import $ from 'jquery'
import 'slick-carousel'
window.$ = window.jQuery = $

const timeline = function () {
  $('.timeline-slider--top').slick({
    slidesToShow: 8,
    slidesToScroll: 1,
    arrows: true,
    infinite: false,
    focusOnSelect: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  })

  $('.timeline-point').on('click', function () {
    const $id = $(this).data('id')
    $('.timeline-point').removeClass('active')
    $(this).addClass('active')
    $('.timeline-description.active').removeClass('active')
    $('#' + $id).addClass('active')
  })

  $('.timeline-slider--top').on('swipe', function () {
    const slickCurrent = $('.slick-current')
    const currentPoint = slickCurrent.find('.timeline-point')
    const $id = currentPoint.data('id')
    $('.timeline-point').removeClass('active')
    $('.timeline-point[data-id="' + $id + '"]').addClass('active')
    $('.timeline-description.active').removeClass('active')
    $('#' + $id).addClass('active')
  })

  $('.timeline-slider--top .slick-arrow').on('click', function () {
    const slickCurrent = $('.slick-current')
    const currentPoint = slickCurrent.find('.timeline-point')
    const $id = currentPoint.data('id')
    $('.timeline-point').removeClass('active')
    $('.timeline-point[data-id="' + $id + '"]').addClass('active')
    $('.timeline-description.active').removeClass('active')
    $('#' + $id).addClass('active')
  })
}

export default timeline
