import $ from 'jquery'
import Glide, {
  Controls,
  Breakpoints,
  Swipe
} from '@glidejs/glide/dist/glide.modular.esm'

const sectionSlider = function () {
  if (document.querySelector('.slider')) {
    if ($('.glide__slides .glide__slide').length > 1) {
      const sectionSliderBlock = new Glide('.slider', {
        type: 'carousel',
        perview: 1,
        focusAt: 'center'
      })
      sectionSliderBlock.mount({ Controls, Breakpoints, Swipe })
    }
  }
}

export default sectionSlider
