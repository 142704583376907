import $ from 'jquery'

const toggleForm = function () {
  $('.toggle-form').on('click', function () {
    $('.clients-info-form').toggleClass('show')
    $('html, body').toggleClass('overflowed')
  })

  $('.provider a').on('click', function (e) {
    e.preventDefault()
  })
}

export default toggleForm
