import $ from 'jquery'
require('particles.js')

const runWeb = function () {
  const redConfig = {
    particles: {
      number: {
        value: 20,
        density: {
          enable: true,
          value_area: 100
        }
      },
      color: {
        value: '#d00100'
      },
      shape: {
        type: 'circle',
        stroke: {
          width: 0,
          color: '#000000'
        },
        polygon: {
          nb_sides: 5
        }
      },
      opacity: {
        value: 0.5,
        random: false,
        anim: {
          enable: false,
          speed: 1,
          opacity_min: 0.1,
          sync: false
        }
      },
      size: {
        value: 5,
        random: true,
        anim: {
          enable: false,
          speed: 40,
          size_min: 0.1,
          sync: false
        }
      },
      line_linked: {
        enable: true,
        distance: 150,
        color: '#d00100',
        opacity: 0.4,
        width: 2
      },
      move: {
        enable: true,
        speed: 6,
        direction: 'none',
        random: false,
        straight: false,
        out_mode: 'out',
        bounce: false,
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200
        }
      }
    },
    interactivity: {
      detect_on: 'canvas',
      events: {
        onhover: {
          enable: true,
          mode: 'grab'
        },
        onclick: {
          enable: true,
          mode: 'push'
        },
        resize: true
      },
      modes: {
        grab: {
          distance: 140,
          line_linked: {
            opacity: 1
          }
        },
        bubble: {
          distance: 400,
          size: 40,
          duration: 2,
          opacity: 8,
          speed: 3
        },
        repulse: {
          distance: 200,
          duration: 0.4
        },
        push: {
          particles_nb: 4
        },
        remove: {
          particles_nb: 2
        }
      }
    },
    retina_detect: true
  }
  const greyConfig = {
    particles: {
      number: {
        value: 20,
        density: {
          enable: true,
          value_area: 100
        }
      },
      color: {
        value: '#c5c5c5'
      },
      shape: {
        type: 'circle',
        stroke: {
          width: 0,
          color: '#c5c5c5'
        },
        polygon: {
          nb_sides: 5
        }
      },
      opacity: {
        value: 0.3,
        random: false,
        anim: {
          enable: false,
          speed: 1,
          opacity_min: 0.1,
          sync: false
        }
      },
      size: {
        value: 5,
        random: true,
        anim: {
          enable: false,
          speed: 40,
          size_min: 0.1,
          sync: false
        }
      },
      line_linked: {
        enable: true,
        distance: 150,
        color: '#c5c5c5',
        opacity: 0.3,
        width: 2
      },
      move: {
        enable: true,
        speed: 6,
        direction: 'none',
        random: false,
        straight: false,
        out_mode: 'out',
        bounce: false,
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200
        }
      }
    },
    interactivity: {
      detect_on: 'canvas',
      events: {
        onhover: {
          enable: true,
          mode: 'grab'
        },
        onclick: {
          enable: true,
          mode: 'push'
        },
        resize: true
      },
      modes: {
        grab: {
          distance: 140,
          line_linked: {
            opacity: 1
          }
        },
        bubble: {
          distance: 400,
          size: 40,
          duration: 2,
          opacity: 8,
          speed: 3
        },
        repulse: {
          distance: 200,
          duration: 0.4
        },
        push: {
          particles_nb: 4
        },
        remove: {
          particles_nb: 2
        }
      }
    },
    retina_detect: true
  }
  if ($('#particles-js').length > 0) {
    var particle1 = particlesJS('particles-js', redConfig)
  }
  if ($('#newses-web').length > 0) {
    var particle2 = particlesJS('newses-web', greyConfig)
  }
  if ($('#slider-web').length > 0) {
    var particle3 = particlesJS('slider-web', greyConfig)
  }
  if ($('#catalogue-web').length > 0) {
    var particle4 = particlesJS('catalogue-web', greyConfig)
  }
  if ($('#contact-form-web').length > 0) {
    var particle5 = particlesJS('contact-form-web', greyConfig)
  }
  if ($('#partner-web').length > 0) {
    var particle6 = particlesJS('partner-web', greyConfig)
  }
  if ($('#newses-hero-web').length > 0) {
    var particle7 = particlesJS('newses-hero-web', greyConfig)
  }
  if ($('#about-web').length > 0) {
    var particle8 = particlesJS('about-web', greyConfig)
  }
  if ($('#weg-web').length > 0) {
    var particle9 = particlesJS('weg-web', greyConfig)
  }
  if ($('#timeline-web').length > 0) {
    var particle10 = particlesJS('timeline-web', greyConfig)
  }
  if ($('#reference-web').length > 0) {
    var particle11 = particlesJS('reference-web', greyConfig)
  }
  if ($('#meet-web').length > 0) {
    var particle12 = particlesJS('meet-web', greyConfig)
  }
  if ($('#mission-web').length > 0) {
    var particle13 = particlesJS('mission-web', greyConfig)
  }
  if ($('#wurth-web').length > 0) {
    var particle14 = particlesJS('wurth-web', greyConfig)
  }
  if ($('#protec-web').length > 0) {
    var particle14 = particlesJS('protec-web', greyConfig)
  }
  if ($('#protec-web-1').length > 0) {
    var particle15 = particlesJS('protec-web-1', greyConfig)
  }
  if ($('#products-web').length > 0) {
    var particle15 = particlesJS('products-web', greyConfig)
  }
  if ($('#protec-child-buy-web').length > 0) {
    var particle15 = particlesJS('protec-child-buy-web', greyConfig)
  }
  if ($('#fega-partner-club-web').length > 0) {
    var particle15 = particlesJS('fega-partner-club-web', greyConfig)
  }
  if ($('#fega-partner-club-about-web').length > 0) {
    var particle15 = particlesJS('fega-partner-club-about-web', greyConfig)
  }
  if ($('#providers-web').length > 0) {
    var particle15 = particlesJS('providers-web', greyConfig)
  }
  if ($('#join-web').length > 0) {
    var particle15 = particlesJS('join-web', greyConfig)
  }
  if ($('#carrier-hero-web').length > 0) {
    var particle15 = particlesJS('carrier-hero-web', greyConfig)
  }
  if ($('#student-web').length > 0) {
    var particle15 = particlesJS('student-web', greyConfig)
  }
  if ($('#sale-web').length > 0) {
    var particle15 = particlesJS('sale-web', greyConfig)
  }
  if ($('#branch-hero-web').length > 0) {
    var particle15 = particlesJS('branch-hero-web', greyConfig)
  }
  if ($('#branch-info-web').length > 0) {
    var particle15 = particlesJS('branch-info-web', greyConfig)
  }
  if ($('#product-hero-web').length > 0) {
    var particle15 = particlesJS('product-hero-web', greyConfig)
  }
  if ($('#providers-web').length > 0) {
    var particle15 = particlesJS('providers-web', greyConfig)
  }
  if ($('#products-list-web').length > 0) {
    var particle15 = particlesJS('products-list-web', greyConfig)
  }
  if ($('#promotion-web-1').length > 0) {
    var particle15 = particlesJS('promotion-web-1', greyConfig)
  }
  if ($('#promotion-web-2').length > 0) {
    var particle15 = particlesJS('promotion-web-2', greyConfig)
  }
  if ($('#promotion-web-3').length > 0) {
    var particle15 = particlesJS('promotion-web-3', greyConfig)
  }
  if ($('#promotion-web-4').length > 0) {
    var particle15 = particlesJS('promotion-web-4', greyConfig)
  }
  if ($('#promotion-web-5').length > 0) {
    var particle15 = particlesJS('promotion-web-5', greyConfig)
  }

  if ($('#slider-web-1').length > 0) {
    var particle3 = particlesJS('slider-web-1', greyConfig)
  }
  if ($('#newses-web-1').length > 0) {
    var particle2 = particlesJS('newses-web-1', greyConfig)
  }
  if ($('#slider-web-1').length > 0) {
    var particle3 = particlesJS('slider-web', greyConfig)
  }
  if ($('#catalogue-web-1').length > 0) {
    var particle4 = particlesJS('catalogue-web-1', greyConfig)
  }
  if ($('#contact-form-web-1').length > 0) {
    var particle5 = particlesJS('contact-form-web-1', greyConfig)
  }
  if ($('#partner-web-1').length > 0) {
    var particle6 = particlesJS('partner-web-1', greyConfig)
  }
  if ($('#newses-hero-web-1').length > 0) {
    var particle7 = particlesJS('newses-hero-web-1', greyConfig)
  }
  if ($('#about-web-1').length > 0) {
    var particle8 = particlesJS('about-web-1', greyConfig)
  }
  if ($('#weg-web-1').length > 0) {
    var particle9 = particlesJS('weg-web-1', greyConfig)
  }
  if ($('#timeline-web-1').length > 0) {
    var particle10 = particlesJS('timeline-web-1', greyConfig)
  }
  if ($('#reference-web-1').length > 0) {
    var particle11 = particlesJS('reference-web-1', greyConfig)
  }
  if ($('#meet-web-1').length > 0) {
    var particle12 = particlesJS('meet-web-1', greyConfig)
  }
  if ($('#mission-web-1').length > 0) {
    var particle13 = particlesJS('mission-web-1', greyConfig)
  }
  if ($('#wurth-web-1').length > 0) {
    var particle14 = particlesJS('wurth-web-1', greyConfig)
  }
  if ($('#protec-web-1').length > 0) {
    var particle14 = particlesJS('protec-web-1', greyConfig)
  }
  if ($('#protec-web-1-1').length > 0) {
    var particle15 = particlesJS('protec-web-1-1', greyConfig)
  }
  if ($('#products-web-1').length > 0) {
    var particle15 = particlesJS('products-web-1', greyConfig)
  }
  if ($('#protec-child-buy-web-1').length > 0) {
    var particle15 = particlesJS('protec-child-buy-web-1', greyConfig)
  }
  if ($('#fega-partner-club-web-1').length > 0) {
    var particle15 = particlesJS('fega-partner-club-web-1', greyConfig)
  }
  if ($('#fega-partner-club-about-web-1').length > 0) {
    var particle15 = particlesJS('fega-partner-club-about-web-1', greyConfig)
  }
  if ($('#providers-web-1').length > 0) {
    var particle15 = particlesJS('providers-web-1', greyConfig)
  }
  if ($('#join-web-1').length > 0) {
    var particle15 = particlesJS('join-web-1', greyConfig)
  }
  if ($('#carrier-hero-web-1').length > 0) {
    var particle15 = particlesJS('carrier-hero-web-1', greyConfig)
  }
  if ($('#student-web-1').length > 0) {
    var particle15 = particlesJS('student-web-1', greyConfig)
  }
  if ($('#sale-web-1').length > 0) {
    var particle15 = particlesJS('sale-web-1', greyConfig)
  }
  if ($('#branch-hero-web-1').length > 0) {
    var particle15 = particlesJS('branch-hero-web-1', greyConfig)
  }
  if ($('#branch-info-web-1').length > 0) {
    var particle15 = particlesJS('branch-info-web-1', greyConfig)
  }
  if ($('#product-hero-web-1').length > 0) {
    var particle15 = particlesJS('product-hero-web-1', greyConfig)
  }
  if ($('#providers-web-1').length > 0) {
    var particle15 = particlesJS('providers-web-1', greyConfig)
  }
  if ($('#products-list-web-1').length > 0) {
    var particle15 = particlesJS('products-list-web-1', greyConfig)
  }
  if ($('#promotion-web-1-1').length > 0) {
    var particle15 = particlesJS('promotion-web-1-1', greyConfig)
  }
  if ($('#promotion-web-2-2').length > 0) {
    var particle15 = particlesJS('promotion-web-2-2', greyConfig)
  }
  if ($('#promotion-web-3-3').length > 0) {
    var particle15 = particlesJS('promotion-web-3-3', greyConfig)
  }
  if ($('#promotion-web-4-4').length > 0) {
    var particle15 = particlesJS('promotion-web-4-4', greyConfig)
  }
  if ($('#promotion-web-5-5').length > 0) {
    var particle15 = particlesJS('promotion-web-5-5', greyConfig)
  }
}

export default runWeb
