import $ from 'jquery'
import AOS from 'aos'
window.$ = jQuery
require('jq-numscroll/src/js/core')

const fireCounters = () => {
  AOS.init()
  document.addEventListener('aos:in', ({ detail }) => {
    if (detail === document.querySelector('.counter')) {

      var number1 = $('.counter-1').text()
      $('.counter-1').numScroll({
        number: number1,
        time: 1000,
      })
      var number2 = $('.counter-2').text()
      $('.counter-2').numScroll({
        number: number2,
        time: 1000,
      })
      var number3 = $('.counter-3').text()
      $('.counter-3').numScroll({
        number: number3,
        time: 1000,
      })
    }
  })
}

export default fireCounters
