import $ from 'jquery'
import '../src/sass/style.scss'
import toggleNavigation from './js/navigation'
import sectionSlider from './js/section-slider/section-slider'
import referencesSlider from './js/section-references/references-slider'
import fireCounters from './js/section-counter/section-counter'
import tickCheck from './js/section-contact-form/acceptance'
import toggleContactData from './js/section-branches'
import timeline from './js/timeline'
import toggleForm from './js/toggle-clients-form'
// import showJob from './js/show-job'
import runWeb from './js/particles'

$(document).ready(function () {
  toggleNavigation()
  sectionSlider()
  fireCounters()
  tickCheck()
  referencesSlider()
  toggleContactData()
  timeline()
  toggleForm()
  // showJob()
  runWeb()
}) 
