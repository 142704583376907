import $ from 'jquery'

const tickCheck = () => {
  $('.wpcf7-list-item-label').on('click', function(e) {
    if (
      $(this)
        .closest('.form__row')
        .hasClass('active')
    ) {
      $(this)
        .closest('.form__row')
        .removeClass('active')
    } else {
      $(this)
        .closest('.form__row')
        .addClass('active')
    }
  })
}

export default tickCheck
