import $ from 'jquery'

const referencesSlider = function () {
  if (document.querySelector('.references-slider')) {


    $('.references-slider').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow:
        "<button class='slider__arrow slider__arrow--prev glide__arrow glide__arrow--prev' data-glide-dir='<'><svg width='24' height='24' fill-rule='evenodd' clip-rule='evenodd'><path d='M20 .755l-14.374 11.245 14.374 11.219-.619.781-15.381-12 15.391-12 .609.755z'/></svg></button>",
      nextArrow:
        "<button class='slider__arrow slider__arrow--next glide__arrow glide__arrow--next' data-glide-dir='>'><svg width='24' height='24' fill-rule='evenodd' clip-rule='evenodd'> <path d='M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z' /></svg></button>",
      infinite: false,
      focusOnSelect: true,
      dots: false,
      responsive: [
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    })
  }
}

export default referencesSlider
