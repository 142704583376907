import $ from 'jquery'
import scrollTo from 'jquery.scrollTo'

const toggleNavigation = function () {
  const burgerButton = $('.burger')
  const mainMenu = $('.navigation__menu')

  burgerButton.on('click', function () {
    burgerButton.toggleClass('active')
    mainMenu.toggleClass('active').slideToggle(200)
  })

  const scrollToContact = $('.contact-slide')

  scrollToContact.on('click', function (e) {
    e.preventDefault()
    $.scrollTo($($(this).attr('href')), 800, { offset: -120 })
  })

  if ($('body').hasClass('single-aktualnosc')) {
    $("a:contains('Aktualności')")
      .parent()
      .addClass('current-menu-item')
  }
  if ($('body').hasClass('single-asortyment')) {
    if ($("a[href='#']:contains('Asortyment')")) {
      $("a[href='#']:contains('Asortyment')")
        .parent()
        .addClass('current-menu-item')
    }
  }
  if ($('body').hasClass('single-oddzial')) {
    if ($("a:contains('Nasze oddziały')")) {
      $("a:contains('Nasze oddziały')")
        .parent()
        .addClass('current-menu-item')
    }
  }

  // $('.menu-item-has-children a')
  //   .stop(true, true)
  //   .mouseover(function(e) {
  //     $(this)
  //       .parent()
  //       .find('.sub-menu')
  //       .stop(true, true)
  //       .slideDown(300)
  //     console.log(e.target)
  //   })
  $('.menu-item-has-children a').hover(function () {
    $(this)
      .parent()
      .find('.sub-menu')
      .stop(true, true)
      .slideDown(300)
  })
  $('.menu-item-has-children')
    .stop(true, true)
    .mouseleave(function (e) {
      $(this)
        .find('.sub-menu')
        .stop(true, true)
        .slideUp(300)
    })

  if (
    $('body').hasClass('page-template-page-about') ||
    $('body').hasClass('page-template-page-group-wurth') ||
    $('body').hasClass('page-template-page-products') ||
    $('body').hasClass('page-template-page-producers')
  ) {
    const thisItem = $('.current-menu-parent').find('.current-menu-item')
    thisItem.closest('.current-menu-parent').addClass('bg-parent')
  }
}

export default toggleNavigation
